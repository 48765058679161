const styles = (theme) => ({
  text: {
    margin: 0,
    "& p": {
      color: theme.palette.common.white,
      fontSize: "0.9rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.2rem",
      },
    },
    textAlign: "justify",
    "& ul": {
      listStyleType: "circle",
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& li": {
      fontSize: "0.9rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.2rem",
      },
    },
  },
});
export default styles;

import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BookingModal from "../components/BookingModal";
import Content from "../components/Content";
import ContentJustified from "../components/ContentJustified";
import { useTheme } from "@material-ui/core/styles";
import { Button, Container, Grid } from "@material-ui/core";
import Cards from "../components/Cards";
import HeaderSection from "../components/HeaderSection";
import { Link } from "gatsby";

import SEO from "../components/SEO";
import SyllabusRequest from "../components/SyllabusRequest";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SectionDecoration from "../components/SectionDecoration";
import Section from "../components/Section";
import Methodology from "../components/Methodology";

const CoursesPageTemplate = ({
  content,
  contentComponent,
  courses,
  methodology,
  location,
}) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const PostContent = contentComponent || Content;
  return (
    <React.Fragment>

      <Section>
        <HeaderSection header={courses.title} location={location} subheader={courses.description} />
        {/* <BookingModal variant="contained" color="primary" /> */}
        <Button
          variant="text"

          component={Link}
          color="primary"
          to="/advisors"
        >Talk to our advisors
        </Button>
        <Cards location={location} cards={courses.blurbs} primary={true} variant="overlay" />


      </Section>
      <SectionDecoration
        color={theme.palette.common.white}
        bgColor={theme.palette.primary.main}
      >
        <Methodology content={methodology} />
      </SectionDecoration>
      <Container maxWidth="lg">
        {/* <CourseCards location={location} /> */}

        <PostContent content={content} />
      </Container>

      <Container maxWidth="lg">



        {/* {blurbs.map((blurb) => (
          <HeaderSection
            key={blurb.title}
            header={blurb.title}
            subheader={blurb.text}
            location={location}
          />
        ))} */}

        {smDown && (
          <React.Fragment>
            <HeaderSection
              header="Request our Syllabus"
              subheader="Check out what projects you will do and what technologies you will learn in details"
              location={location}
            />
            {/* <SyllabusRequest
              color={theme.palette.common.white}
              isMobile={true}
            /> */}
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
};

CoursesPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.object,
  description: PropTypes.string,
  nextStarts: PropTypes.array,
  courses: PropTypes.object,
  testimonials: PropTypes.array,
  methodology: PropTypes.shape({}),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const CoursesPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.title}
        location={location}
        description={
          "Project based coding school with focus on programming logic, story telling and critical thinking."
        }
      />

      <CoursesPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        courses={frontmatter.courses}
        content={html}
        contentComponent={ContentJustified}
        methodology={frontmatter.methodology}
        heading={frontmatter.heading}
        description={frontmatter.description}
        nextStarts={frontmatter.nextStarts}
        blurbs={frontmatter.blurbs}
        location={location}
      />
    </Layout>
  );
};

CoursesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CoursesPage;

export const coursesPageQuery = graphql`
  query CoursesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        description
        blurbs {
          title
          text
        }
        courses {
          title
          description
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }              
              }
            }
            title
            text
            link 
          }
        }
        methodology {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

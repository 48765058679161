import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import styles from "./styles";
import HTMLContent from "../Content";

const Methodology = ({ content, classes }) => {
  return (
    <Container maxWidth="lg" component="main">
      <Typography
        component="h2"
        variant="h3"
        style={{ padding: 0, marginTop: 20 }}
      >
        {content?.title}
      </Typography>
      {content && <HTMLContent
        content={content.description.childMarkdownRemark.html}
        className={classes?.text}
      />}
    </Container>
  );
};

export default withStyles(styles)(Methodology);
